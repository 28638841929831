import home from './home';
import ChastiaJob from '../views/chastia-job/ChastiaJob.vue';
// import NewChastiaJob from '../views/chastia-job/NewChastiaJob.vue';
// import ChastiaJobDetail from '../views/chastia-job/ChastiaJobDetail.vue';

const chastiaJob = {
  path: '/chastia-job',
  name: 'Chastia Tickets',
  component: ChastiaJob,
  meta: { parent: home },
};

// const newChastiaJob = {
//   path: '/chastia-job/new',
//   name: 'New License Plate',
//   component: NewChastiaJob,
//   meta: { parent: chastiaJob },
// };

// const chastiaJobDetail = {
//   path: '/chastia-job/detail/:pk',
//   name: 'License Plate Detail',
//   redirect: '/chastia-job/detail/:pk/chastia-job',
//   component: ChastiaJobDetail,
//   meta: { parent: chastiaJob, noLink: true },
// };

// Tabs
// import ChastiaJobTab from '../views/chastia-job/tabs/ChastiaJobTab.vue';
// import LocationTab from '../views/chastia-job/tabs/LocationTab.vue';
// import UserAccessTab from '../views/chastia-job/tabs/UserAccessTab.vue';

// chastiaJobDetail.children = [];

const chastiaJobRoutes = [
  chastiaJob,
  // newChastiaJob,
  // chastiaJobDetail
];

export default chastiaJobRoutes;
