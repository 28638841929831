import DataStorageFactory from '../abstract/data-storage-factory.js';
import ChastiaDocument from './chastia-document-class';

/** @extends DataStorageFactory<ChastiaDocument> */
class ChastiaDocumentFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'name';
  getModelName = () => 'chastia-document';
  getPrettyName = () => 'Chastia Document';
}

const chastiaDocumentFactory = new ChastiaDocumentFactory(ChastiaDocument.prototype);

export default chastiaDocumentFactory;
