<template>
  <card
    class="page-chastia-jobs"
    title="All Chastia tickets"
    description="The list of all Chastia tickets synced to Phillip."
  >
    <phillip-table table="chastia-job" hide-new-button hide-delete-button edit-button-label="View" />
  </card>
</template>

<script>
export default {
  name: 'page-chastia-jobs',
};
</script>
