import lazyDisplay from './chastia-document-lazy-display';
import factory from '../../model/chastia-document/chastia-document-factory';

const state = {
  /**
   * The factory
   */
  factory,
};

const getters = {
  /**
   * Returns the factory
   *
   * @param {state} state
   */
  getFactory: ({ factory }) => factory,
};

const actions = {};

const mutations = {};

const modules = {
  lazyDisplay,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
