<template>
  <card
    class="page-chastia-documents"
    title="All Chastia documents"
    description="The list of all Chastia documents synced to Phillip."
  >
    <phillip-table table="chastia-document" hide-new-button hide-delete-button edit-button-label="View" />
  </card>
</template>

<script>
export default {
  name: 'page-chastia-documents',
};
</script>
