import home from './home';
import ChastiaDocument from '../views/chastia-document/ChastiaDocument.vue';
// import NewChastiaDocument from '../views/chastia-document/NewChastiaDocument.vue';
// import ChastiaDocumentDetail from '../views/chastia-document/ChastiaDocumentDetail.vue';

const chastiaDocument = {
  path: '/chastia-document',
  name: 'Chastia Documents',
  component: ChastiaDocument,
  meta: { parent: home },
};

// const newChastiaDocument = {
//   path: '/chastia-document/new',
//   name: 'New License Plate',
//   component: NewChastiaDocument,
//   meta: { parent: chastiaDocument },
// };

// const chastiaDocumentDetail = {
//   path: '/chastia-document/detail/:pk',
//   name: 'License Plate Detail',
//   redirect: '/chastia-document/detail/:pk/chastia-document',
//   component: ChastiaDocumentDetail,
//   meta: { parent: chastiaDocument, noLink: true },
// };

// Tabs
// import ChastiaDocumentTab from '../views/chastia-document/tabs/ChastiaDocumentTab.vue';
// import LocationTab from '../views/chastia-document/tabs/LocationTab.vue';
// import UserAccessTab from '../views/chastia-document/tabs/UserAccessTab.vue';

// chastiaDocumentDetail.children = [];

const chastiaDocumentRoutes = [
  chastiaDocument,
  // newChastiaDocument,
  // chastiaDocumentDetail
];

export default chastiaDocumentRoutes;
