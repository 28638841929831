import DataStorageFactory from '../abstract/data-storage-factory.js';
import ChastiaJob from './chastia-job-class';

/** @extends DataStorageFactory<ChastiaJob> */
class ChastiaJobFactory extends DataStorageFactory {
  getPrimaryKey = () => 'id';
  getTitleKey = () => 'name';
  getModelName = () => 'chastia-job';
  getPrettyName = () => 'Chastia Job';
}

const chastiaJobFactory = new ChastiaJobFactory(ChastiaJob.prototype);

export default chastiaJobFactory;
