<template>
  <component :is="`${display}-lazy-display`" v-bind="$attrs" />
</template>

<script>
import AccessTokenLazyDisplay from './AccessTokenLazyDisplay';
import AppShortcutLazyDisplay from './AppShortcutLazyDisplay';
import AreaLazyDisplay from './AreaLazyDisplay';
import ArticleLazyDisplay from './ArticleLazyDisplay';
import BillingAddressLazyDisplay from './BillingAddressLazyDisplay';
import BuildingLazyDisplay from './BuildingLazyDisplay';
import CameraLazyDisplay from './CameraLazyDisplay';
import ChastiaDeviceLazyDisplay from './ChastiaDeviceLazyDisplay';
import ChastiaDeviceMeterTypeLazyDisplay from './ChastiaDeviceMeterTypeLazyDisplay';
import ChastiaDocumentLazyDisplay from './ChastiaDocumentLazyDisplay';
import ChastiaFunctionLazyDisplay from './ChastiaFunctionLazyDisplay';
import ChastiaJobLazyDisplay from './ChastiaJobLazyDisplay';
import ChastiaSensorLazyDisplay from './ChastiaSensorLazyDisplay';
import ChastiaSensorMeasurementLazyDisplay from './ChastiaSensorMeasurementLazyDisplay';
import ChastiaSensorMeasuringTypeLazyDisplay from './ChastiaSensorMeasuringTypeLazyDisplay';
import ControlledEntryLazyDisplay from './ControlledEntryLazyDisplay';
import DoorBellLazyDisplay from './DoorBellLazyDisplay';
import EmailMessageLazyDisplay from './EmailMessageLazyDisplay';
import FaqLazyDisplay from './FaqLazyDisplay';
import FirebaseMessageLazyDisplay from './FirebaseMessageLazyDisplay';
import IosVoipMessageLazyDisplay from './IosVoipMessageLazyDisplay';
import LicensePlateLazyDisplay from './LicensePlateLazyDisplay';
import RfidLazyDisplay from './RfidLazyDisplay';
import RfidOwnerChangelogLazyDisplay from './RfidOwnerChangelogLazyDisplay';
import SessionLazyDisplay from './SessionLazyDisplay';
import SipDoorLazyDisplay from './SipDoorLazyDisplay';
import SipDoorActionLazyDisplay from './SipDoorActionLazyDisplay';
import UserLazyDisplay from './UserLazyDisplay';
import UserGroupLazyDisplay from './UserGroupLazyDisplay';
import UserSipAccountLazyDisplay from './UserSipAccountLazyDisplay';

export default {
  name: 'phillip-lazy-display',
  components: {
    AccessTokenLazyDisplay,
    AppShortcutLazyDisplay,
    AreaLazyDisplay,
    ArticleLazyDisplay,
    BillingAddressLazyDisplay,
    BuildingLazyDisplay,
    CameraLazyDisplay,
    ChastiaDeviceLazyDisplay,
    ChastiaDeviceMeterTypeLazyDisplay,
    ChastiaDocumentLazyDisplay,
    ChastiaFunctionLazyDisplay,
    ChastiaJobLazyDisplay,
    ChastiaSensorLazyDisplay,
    ChastiaSensorMeasurementLazyDisplay,
    ChastiaSensorMeasuringTypeLazyDisplay,
    ControlledEntryLazyDisplay,
    DoorBellLazyDisplay,
    EmailMessageLazyDisplay,
    FaqLazyDisplay,
    FirebaseMessageLazyDisplay,
    IosVoipMessageLazyDisplay,
    LicensePlateLazyDisplay,
    RfidLazyDisplay,
    RfidOwnerChangelogLazyDisplay,
    SessionLazyDisplay,
    SipDoorLazyDisplay,
    SipDoorActionLazyDisplay,
    UserLazyDisplay,
    UserGroupLazyDisplay,
    UserSipAccountLazyDisplay,
  },
  props: {
    display: {
      type: String,
      required: true,
    },
  },
};
</script>
